import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';

import { Stack } from '@mui/system';
import { TabList, TabPanel, TabContext } from '@mui/lab';
import { Box, Tab, Grid, Backdrop, IconButton } from '@mui/material';

import { useAuth } from 'src/hooks/useAuth';
import useResponsiveScreen from 'src/hooks/useResponsiveScreen';

import { genericApiToaster } from 'src/services/utils';
import { getDepartmentById } from 'src/services/department.service';

import Loader from 'src/components/loaders/Loader';
import Iconify from 'src/components/iconify/Iconify';

import TicketDelegation from 'src/sections/ticketDetails/subSections/TicketDelegation';

import InfoTab from './subSections/InfoTab';
import { decrypt } from '../../utils/hashCode';
import { useToast } from '../../hooks/useToast';
import DetailsContent from './subSections/DetailsContent';
import { fetchStatuses } from '../../services/status.service';
import TicketConversation from './ticketConversation/TicketConversation';
import { getUserById, getUsersByDepartment } from '../../services/user.service';
import { gridStyle, iconifyStyle, secondBoxtStyle, secondGridStyle } from './styles';
import {
  updateTicket,
  getTicketById,
  assignTicketManually,
} from '../../services/ticket.service';

export default function TicketDetailsView() {
  const { id: currentUserId, role: currentUserRole } = useAuth();

  const [departmentData, setDepartmentData] = useState({
    id: 0,
    name: '',
    description: '',
    image: '',
  });
  const [ticketData, setTicketData] = useState();
  const [statusesList, setStatusesList] = useState([]);
  const [currentStatus, setCurrentStatus] = useState({});
  const [departmentUsers, setDepartmentUsers] = useState([]);
  const [tabValue, setTabValue] = useState('1');
  const [isLoading, setIsloading] = useState(false);
  const [commentsData, setCommentsData] = useState([]);
  const [customerData, setCustomerData] = useState({
    id: 0,
    username: '',
    email: '',
  });
  const [agentData, setAgentData] = useState({
    id: 0,
    username: '',
    email: '',
    departmentId: 0,
  });

  const { t } = useTranslation();
  const { isSmallScreen } = useResponsiveScreen();
  const { ticketId } = useParams();
  const [decryptedTicketId, setDecryptedTicketId] = useState();
  const navigate = useNavigate();
  const { showToast, hideToast } = useToast();

  const fetchTicketData = async (id) => {
    setIsloading(true);
    try {
      const res = await getTicketById(id);
      setTicketData(res);
    } catch (error) {
      navigate('/404');
    }
  };

  useEffect(() => {
    if (ticketId) {
      const decryptedId = decrypt(ticketId);
      fetchTicketData(decryptedId);
      setDecryptedTicketId(decryptedId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchUser = async (userId, setState) => {
    if (userId !== -1) {
      try {
        const res = await getUserById(userId);
        setState(res);
      } catch (err) {
        showToast({
          message: t('errorMessages.couldntLoadUser'),
          severity: 'error',
          props: { hideToast },
        });
      }
    }
  };

  const fetchDepartment = async (depId) => {
    try {
      const res = await getDepartmentById(depId);
      setDepartmentData(res);
    } catch (error) {
      showToast({
        message: t('errorMessages.couldntLoadDep'),
        severity: 'error',
        props: { hideToast },
      });
    }
  };

  const assignTicket = async (userId, statusId) => {
    await genericApiToaster(
      assignTicketManually,
      [parseInt(decryptedTicketId, 10), userId, statusId],
      t('successMessages.successTicketAssign'),
      t('errorMessages.errorUpdatingTicket'),
      showToast,
      hideToast,
      () => {
        fetchTicketData(decryptedTicketId);
      }
    );
  };

  const delegateTicket = (delegatedTicketData) => {
    genericApiToaster(
      updateTicket,
      delegatedTicketData,
      currentUserRole.authority === 'ROLE_AGENT' &&
        ticketData?.assignedTo === currentUserId
        ? t('successMessages.successDelegateTicket')
        : t('successMessages.successReassignTicket'),
      currentUserRole.authority === 'ROLE_AGENT' &&
        ticketData?.assignedTo === currentUserId
        ? t('errorMessages.couldntDelegateTicket')
        : t('errorMessages.couldntReassignTicket'),
      showToast,
      hideToast,
      () => {
        fetchTicketData(decryptedTicketId);
      }
    );
  };

  const getStatuses = async (depId) => {
    try {
      const res = await fetchStatuses();
      setStatusesList(
        res.filter((status) => status.departmentId === depId || status.departmentId === 0)
      );
    } catch (error) {
      showToast({
        message: t('errorMessages.couldntLadStatuses'),
        severity: 'error',
        props: { hideToast },
      });
    }
  };
  const fetchDepAgents = async () => {
    try {
      const res = await getUsersByDepartment(ticketData.departmentId);
      setDepartmentUsers(
        res.filter((user) => user.appUserRole === 'ROLE_AGENT' && !user.archived)
      );
    } catch (error) {
      showToast({
        message: t('errorMessages.couldntLoadDepUsers'),
        severity: 'error',
        props: { hideToast },
      });
    }
  };

  useEffect(() => {
    if (
      ticketData &&
      currentStatus &&
      currentStatus.systemMappedStatus !== 'UNASSIGNED' &&
      agentData.departmentId !== 0
    )
      fetchDepartment(agentData.departmentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentData]);

  const getCurrentStatus = (idStatus) => {
    setCurrentStatus(statusesList.find((status) => status.statusId === idStatus));
  };

  useEffect(() => {
    if (ticketData) {
      if (currentStatus && currentStatus.systemMappedStatus !== 'UNASSIGNED') {
        fetchUser(ticketData.assignedTo, setAgentData);
      }
      fetchUser(ticketData.createdBy, setCustomerData);
      fetchDepAgents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketData, currentStatus]);

  useEffect(() => {
    getStatuses(ticketData?.departmentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketData]);

  useEffect(() => {
    if (ticketData) {
      getCurrentStatus(ticketData.status_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusesList, ticketData]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Backdrop
        sx={{
          bgcolor: 'rgb(121 121 121 / 32%)',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isLoading}
      >
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <Loader size={175} hideText />
        </Box>
      </Backdrop>
      <Grid columns={5} sx={gridStyle} container spacing={1}>
        <Grid item xs={5} lg={2} md={2} sm={5} bgcolor="#F5F5F5">
          <TabContext value={tabValue}>
            <Box
              sx={{
                marginLeft: '30px',
                marginRight: isSmallScreen ? '10px' : '30px',
                borderBottom: 1,
                borderColor: 'divider',
              }}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <TabList
                onChange={handleTabChange}
                aria-label="ticket details tabs "
                sx={{ width: '100%' }}
              >
                <Tab
                  icon={
                    <Iconify width={isSmallScreen ? 20 : 30} icon="akar-icons:info" />
                  }
                  style={{ color: '#121212', width: '50%' }}
                  iconPosition="bottom"
                  value="1"
                />

                <Tab
                  sx={{ width: '50%' }}
                  icon={
                    <Iconify width={isSmallScreen ? 22 : 30} icon="ic:baseline-history" />
                  }
                  style={{
                    color: '#121212',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  value="2"
                />
              </TabList>
              {isSmallScreen && (
                <Box sx={secondBoxtStyle}>
                  <Stack direction="row" justifyContent="end" alignItems="end">
                    <IconButton
                      onClick={() => {
                        if (window.opener) {
                          window.close();
                        } else {
                          navigate(-1);
                        }
                      }}
                    >
                      <Iconify
                        sx={{ ...iconifyStyle, width: 25 }}
                        icon="eva:close-square-fill"
                      />
                    </IconButton>
                  </Stack>
                </Box>
              )}
            </Box>

            <TabPanel value="1" xs={5} sm={5} md={2} sx={{ padding: '0px 24px' }}>
              <DetailsContent
                row={ticketData}
                status={currentStatus}
                statusesList={statusesList}
                departmentUsers={departmentUsers}
                fetchTicketData={fetchTicketData}
              />
              <TicketDelegation
                currentStatus={currentStatus}
                ticketData={ticketData}
                departmentUsers={departmentUsers}
                assignTicket={assignTicket}
                ticketId={decryptedTicketId}
                statusesList={statusesList}
                delegateTicket={delegateTicket}
              />
            </TabPanel>
            <TabPanel value="2" sx={{ padding: '0px 24px' }}>
              <InfoTab
                fetchUser={fetchUser}
                currentStatus={currentStatus}
                ticketData={ticketData}
                assignTicket={assignTicket}
                ticketId={decryptedTicketId}
                statusesList={statusesList}
                departmentUsers={departmentUsers}
                agentData={agentData}
                customerData={customerData}
                departmentData={departmentData}
                commentsData={commentsData}
              />
            </TabPanel>
          </TabContext>
        </Grid>

        <Grid item xs={5} lg={3} md={3} sm={5} sx={secondGridStyle}>
          <TicketConversation
            statusesList={statusesList}
            fetchUser={fetchUser}
            row={ticketData}
            customerAvatar={customerData?.avatar}
            agentAvatar={agentData?.avatar}
            currentStatus={currentStatus}
            setIsLoading={setIsloading}
            commentsData={commentsData}
            setCommentsData={setCommentsData}
            ticketId={decryptedTicketId}
            departmentUsers={departmentUsers}
          />
        </Grid>
      </Grid>
    </>
  );
}
